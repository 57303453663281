<template>
  <div>
    <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
    <ListPage ref="appTable" :endpoint="apiCall" :headers="headers" title="Staffs" :showActionsColumn="true"
      :showAddButton="isAllowed('createStaff')" :showEditButton="isAllowed('editStaff')"
      :showDeleteButton="isAllowed('deleteStaff')" :showViewButton="isAllowed('viewStaff')"
      @add="navigateTo('/app/staffs/0')" @edit="navigateTo(`/app/staffs/${$event._id}`)"
      @delete="deleteStaff($event._id)" @view="navigateTo(`/app/staffs/view/${$event._id}`)">
      <!-- Mobile View -->
      <template v-slot:item="{ item }" v-if="isMobile">
        <staff-card :id="item._id" :name="`${item.firstName} ${item.lastName}`" :mobileNo="item.mobileNo"
          :email="item.email" :roles="item.roles" :icons="icons" @delete="removeItem"></staff-card>
        <v-divider></v-divider>
      </template>

      <!-- Action Buttons for Web/Tablet View -->
      <template v-slot:action="{ item }" v-if="$vuetify.breakpoint.smAndUp">

        <action-button icon="mdi-pencil" :show="isAllowed('editStaff')"
          @click="$router.push(`/app/staffs/${item._id}`)"></action-button>
        <action-button icon="mdi-delete" :show="isAllowed('deleteStaff')"
          @click="deleteStaff(item._id)"></action-button>
        <!-- mdi-update action for resetStaffPassword -->
        <action-button icon="mdi-update" :show="isAllowed('resetStaffPassword')"
          @click="resetStaffPassword(item._id)"></action-button>
      </template>
    </ListPage>
    <alert-message v-if="msg" :error="msg"></alert-message>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton';
import appConstants from '@/utils/appConstants';
import StaffCard from '@/components/UserResponsiveCard';
import ListPage from '@/components/common/ListPage.vue';

export default {
  components: {
    ActionButton,
    StaffCard,
    ListPage,
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Middle Name', value: 'middleName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Mobile No', value: 'mobileNo' },
        { text: 'E-Mail', value: 'email' },
        { text: 'Actions', value: 'action', align: 'right' },
      ],
      items: [],
      icons: [
        { name: 'mdi-pencil', path: '/app/staffs/', permission: 'editStaff' },
        { name: 'mdi-delete', permission: 'deleteStaff' },
        { name: 'mdi-update', path: '/app/staffs/resetStaffPassword/', permission: 'resetStaffPassword' }, // This was the missing part
      ],
      loading: false,
      apiCall: `${appConstants.STAFFS_API}?userType=STAFF`,
    };
  },
  methods: {
    async deleteStaff(id) {
      try {
        await this.deleteItem(
          'Are you sure you want to delete this Staff Member?',
          `${appConstants.STAFFS_API}/${id}`
        );
        this.items.splice(this.items.findIndex((rec) => rec._id === id), 1);
        this.$refs.appTable.loadData()
      } catch (error) {
        this.handleError(error);
        this.msg = error.response.data;
      }
    },
    resetStaffPassword(id) {
      this.$router.push(`/app/staffs/resetStaffPassword/${id}`);
    },
    forceReset(id) {
      this.$router.push(`/app/staffs/resetStaffPassword/${id}`);
    },
    removeItem(id) {
      this.deleteStaff(id);
    },

    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
/* Additional styles if needed */
</style>